import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import ActionCable from 'actioncable';
import { ActionCableProvider } from 'react-actioncable-provider';
import { SnackbarProvider } from 'notistack';
import theme from './styles/theme';
import * as serviceWorker from './serviceWorker';
import Routes from './routes';
import env from './config/env';
import store from './redux';

const cable = ActionCable.createConsumer(`${env.notifServer}`);
const snackbarProps = {
  maxSnack: 1,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

ReactDOM.render(
  <Provider store={store}>
    <ActionCableProvider cable={cable}>
      <SnackbarProvider {...snackbarProps}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </ThemeProvider>
      </SnackbarProvider>
    </ActionCableProvider>
  </Provider>,
  document.getElementById('root'));
serviceWorker.unregister();
