/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import swal from 'sweetalert';
export async function SetAuthTokenRequest(request) {
  request.headers.common.Accept = 'application/json';
  request.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;

  return request;
}

export const checkTokenExpired = async (error) => {
  const { response: { status, data: { error: err } } } = error;
  if (status === 403 || status === 401) {
    await localStorage.clear();
    window.location = '/';
  } else if (status === 500) swal('Gangguan server!', err, 'error');
  else return error;
};
