/* eslint-disable semi */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Route } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3)
  },
  lists: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  typography: {
    fontSize: 12
  }
}));

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

export default function RouterBreadcrumbs(props) {
  const { breadcrumbNameMap } = props;
  const classes = useStyles();

  const breadcrumbsText = {
    create: 'Tambah',
    edit: 'Ubah'
  }

  return (
    <Paper className={classes.root}>
      <Route>
        {({ location }) => {
          const pathnames = location.pathname.split('/').filter((x) => x);
          let cleanPathnames = _.pull(pathnames, 'app');
          if (location.pathname?.startsWith?.('/app/parameters-management/product/edit')) {
            cleanPathnames.pop()
          }
          return (
            <Breadcrumbs aria-label="breadcrumb" separator="/">
              <LinkRouter color="inherit" to="/app/home/dashboard" className={classes.typography}>
                GHA Sispus Syariah
              </LinkRouter>
              {cleanPathnames?.map?.((value, index) => {
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                return (
                  <Typography key={`${index + 1}`} color="textPrimary" className={classes.typography}>
                    {breadcrumbNameMap[to] || breadcrumbsText[value] || value}
                  </Typography>
                )
              })}
            </Breadcrumbs>
          );
        }}
      </Route>
    </Paper>
  );
}
