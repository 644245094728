const styles = {
  // Styleing React Component

  mainContainer: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#5d1563',

  },
  container: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '70%',
  },

  // Styleing Material Component

  CoustomsStyles: {
    CardMobile: {
      marginBottom: 10,
      marginTop: 10,
      width: 340,
    },
    Card: {
      marginBottom: 10,
      marginTop: 10,
      width: 620,
    },
    input: {
      '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: '#5d1563',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#5d1563',
      },
    },
    links: {
      color: '#5d1563',
      marginTop: 5,
      marginRight: -15,
      fontFamily: 'Open Sans',
    },
    textDesc: {
      fontFamily: 'Open Sans',
    }
  },
};
export default styles;
