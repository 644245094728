import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Route } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    width: '100%',
    height: '100%'
  }
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          staticContext.status = 404; // eslint-disable-line
        }
        return (
          <div>
            <div className={classes.root}>
              <Paper className={classes.paper}>
                Maaf, halaman yang anda tidak ditemukan, silahkan kembali ke dashboard.
                <Link component={Button} href="/app/home/dashboard">
                  Kembali Ke Dashboard ?
                </Link>
              </Paper>
            </div>
          </div>
        );
      }}
    />
  );
};
export default NotFound;
