/* eslint-disable semi */
/* eslint-disable react/prop-types */
import React from 'react';
import { IconButton, Badge } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import useNotification from '../../redux/notification/useNotification'

const CustomBadge = ({
  handleClickNotif,
  classes,
}) => {
  const { unreadCount } = useNotification();
  return (
    <IconButton onClick={handleClickNotif}>
      <Badge badgeContent={unreadCount} color="secondary">
        <NotificationsIcon
          color="primary"
          fontSize="large"
          className={classes.notificationIconMobile}
        />
      </Badge>
    </IconButton>
  )
};

export default CustomBadge;
