/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import React, { Fragment } from 'react';
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Menu,
  Box,
  Typography
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import clsx from 'clsx';
import parse from 'html-react-parser';
import { useHistory, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import useStyles from './notification-jss';
import useNotification from '../../redux/notification/useNotification';

const Notification = ({
  anchorEl2,
  handleClose,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { list, unreadCount, totalCount, readAll, readOne } = useNotification();
  const { pathname } = useLocation();

  const handleReadAllNotif = () => {
    readAll();
    handleClose();
  };

  const handleClickNotif = async (item) => {
    await readOne(item.id);
    handleClose();
    if (item.url) {
      history.push({
        pathname: item.url,
        state: {
          attr_id: item?.attr_id
        }
      });
    }
  };

  return (
    <Menu
      anchorEl={anchorEl2}
      keepMounted
      open={Boolean(anchorEl2)}
      onClose={handleClose}
      classes={{
        paper: classes.notification,
      }}
      MenuListProps={{
        component: 'div'
      }}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Box className="Notification-header">
        {unreadCount}
        {' '}
        Notifikasi Belum Dibaca
      </Box>
      <Box className="Notification-listContainer">
        <List component="div">
          {
            totalCount > 0 && list.map((item, index) => (
              <Fragment key={item.id}>
                { (list.length > 1 && index > 0) && <Divider /> }
                <ListItem
                  key={item.id}
                  button
                  onClick={handleClose}
                  disabled={item.is_read}
                  className={clsx(classes.listItem, !item.is_read && 'Notification-unread')}
                >
                  <ListItemText
                    primary={(
                      <Fragment>
                        <Typography className="Notification-title">{item.subject}</Typography>
                      </Fragment>
                    )}
                    secondary={(
                      <Fragment>
                        <Typography
                          component="div"
                          className="Notification-body"
                        >
                          {!!item.body && parse(item.body)}
                        </Typography>
                        <Typography className="Notification-date">{dayjs(item.created_at).locale('id').format('dddd, DD MMMM YYYY. HH:MM')}</Typography>
                      </Fragment>
                    )}
                    variant="caption"
                    className={classes.secondaryText}
                    onClick={() => handleClickNotif(item)}
                    disableTypography
                  />
                </ListItem>
              </Fragment>
            ))
          }
          {
            !(totalCount > 0) && (
              <ListItem>
                <ListItemText>Tidak Ada Notifikasi</ListItemText>
              </ListItem>
            )
          }
        </List>
      </Box>
      <Box className="Notification-footer">
        <Button
          disabled={unreadCount < 1}
          type="button"
          variant="text"
          color="secondary"
          onClick={handleReadAllNotif}
        >
          Tandai Semua Dibaca
        </Button>
        <Button
          disabled={pathname.startsWith('/app/home/notification')}
          onClick={() => {
            history.replace('/app/home/notification');
            handleClose();
          }}
          type="button"
          variant="text"
          color="secondary"
          endIcon={<ArrowForwardIosIcon size="small" />}
        >
          Lihat Semua Notifikasi
        </Button>
      </Box>
    </Menu>
  );
};

export default Notification;
