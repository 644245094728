/* eslint-disable linebreak-style */
const Icon = {
  logo: require('../assets/icon/logo.png'),
  add: require('../assets/icon/add.png'),
  lock: require('../assets/icon/lock.png'),
  mail: require('../assets/icon/mail.png'),
  warning: require('../assets/icon/warning.png'),
  eye: require('../assets/icon/eye.png'),
  eye2: require('../assets/icon/eye2.png'),
  check: require('../assets/icon/check.png'),
  hands: require('../assets/icon/hands.png'),
  person: require('../assets/icon/person.png'),
  notifications: require('../assets/icon/notifications.png'),
  home_active: require('../assets/icon/home-active.png'),
  close_menu: require('../assets/icon/close-menu.png'),
  open_menu: require('../assets/icon/open-menu.png'),
  error_lock: require('../assets/icon/error-lock.png'),
  error_mail: require('../assets/icon/error-mail.png'),
  succes_mail: require('../assets/icon/succes-mail.png'),
  circle_active: require('../assets/icon/circle-active.png'),
  circle: require('../assets/icon/circle.png'),
  filter: require('../assets/icon/filter.png'),
  search: require('../assets/icon/search.png'),
  edit: require('../assets/icon/edit.png'),
  delete: require('../assets/icon/delete.png'),
  popupDelete: require('../assets/icon/popupDelete.png'),
  close: require('../assets/icon/close.png'),
  active: require('../assets/icon/active.png'),
  nonactive: require('../assets/icon/nonactive.png'),
  selectActive: require('../assets/icon/selectActive.png'),
  selectNonActive: require('../assets/icon/selectNonActive.png'),
  day: require('../assets/icon/day.png'),
  persen: require('../assets/icon/persen.png'),
  rp: require('../assets/icon/rp.png'),
  blank: require('../assets/icon/blank.png'),
  deleteImg: require('../assets/icon/deleteImg.png'),
  auto: require('../assets/icon/auto.png'),
  icon_date: require('../assets/icon/icon_date.png'),
  line: require('../assets/icon/line.png'),
  check2: require('../assets/icon/check2.png'),
  dash: require('../assets/icon/dash.png'),
};
export default Icon;
