/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import Loadable from 'react-loadable';
import { Loader as Loading } from '../../components/Loader';

export const DashboardPage = Loadable({
  loader: () => import('./Dashboard'),
  loading: Loading
});

// FINANCIAL MANAGEMENT
export const BranchAccount = Loadable({
  loader: () => import('./FinancialManagement/BranchAccount'),
  loading: Loading
});

export const CentralAccount = Loadable({
  loader: () => import('./FinancialManagement/CentralAccount'),
  loading: Loading
});

// MASTER DATA
export const OfficeArea = Loadable({
  loader: () => import('./MasterData/OfficeArea'),
  loading: Loading
});

export const Bank = Loadable({
  loader: () => import('./MasterData/Bank'),
  loading: Loading
});

export const BranchOffice = Loadable({
  loader: () => import('./MasterData/BranchOffice'),
  loading: Loading
});

export const City = Loadable({
  loader: () => import('./MasterData/City'),
  loading: Loading
});

export const Country = Loadable({
  loader: () => import('./MasterData/Country'),
  loading: Loading
});

export const HeadOffice = Loadable({
  loader: () => import('./MasterData/HeadOffice'),
  loading: Loading
});

export const Province = Loadable({
  loader: () => import('./MasterData/Province'),
  loading: Loading
});

export const RegionalOffice = Loadable({
  loader: () => import('./MasterData/RegionalOffice'),
  loading: Loading
});

export const UnitOffice = Loadable({
  loader: () => import('./MasterData/UnitOffice'),
  loading: Loading
});

export const Profession = Loadable({
  loader: () => import('./MasterData/Profession'),
  loading: Loading
});

export const User = Loadable({
  loader: () => import('./MasterData/User'),
  loading: Loading
});

export const Role = Loadable({
  loader: () => import('./MasterData/Role'),
  loading: Loading
});

// PARAMETER MENAGEMENT

export const Product = Loadable({
  loader: () => import('./ParameterManagement/Product'),
  loading: Loading
});

export const TypeOfColleteral = Loadable({
  loader: () => import('./ParameterManagement/TypeOfColleteral'),
  loading: Loading
});

export const MasterCurrency = Loadable({
  loader: () => import('./ParameterManagement/MasterCurrency'),
  loading: Loading
});

export const OneObligor = Loadable({
  loader: () => import('./ParameterManagement/OneObligor'),
  loading: Loading
});

export const PettyCash = Loadable({
  loader: () => import('./ParameterManagement/PettyCash'),
  loading: Loading
});

// ACCOUNTING MANAGEMENT
export const Coa = Loadable({
  loader: () => import('./AccountingManagement/Coa'),
  loading: Loading
});

export const NonTransactional = Loadable({
  loader: () => import('./AccountingManagement/NonTransactional'),
  loading: Loading
});

// Settings Menu
export const Sispus = Loadable({
  loader: () => import('./Settings/MenuSispus'),
  loading: Loading
});

export const Siscab = Loadable({
  loader: () => import('./Settings/MenuSiscab'),
  loading: Loading
});

export const AssignMenuToRole_SisPus = Loadable({
  loader: () => import('./Settings/AssignMenuToRole_SisPus'),
  loading: Loading
});

export const AssignMenuToRole_SisCab = Loadable({
  loader: () => import('./Settings/AssignMenuToRole_SisCab'),
  loading: Loading
});

// Approval

export const WorkcapApproval = Loadable({
  loader: () => import('./Approval/Workcap'),
  loading: Loading
});

export const DeviationApproval = Loadable({
  loader: () => import('./Approval/Deviation'),
  loading: Loading
});

export const TransactionCanceling = Loadable({
  loader: () => import('./Approval/TransactionCanceling'),
  loading: Loading
});

// Setting

export const Profile = Loadable({
  loader: () => import('./Settings/Profile'),
  loading: Loading
});

export const ChangePassword = Loadable({
  loader: () => import('./Settings/ChangePassword'),
  loading: Loading
});

// Report

export const Stock = Loadable({
  loader: () => import('./Report/Stock'),
  loading: Loading
});

export const Transaction = Loadable({
  loader: () => import('./Report/Transaction'),
  loading: Loading
});

export const Customer = Loadable({
  loader: () => import('./Report/Customer'),
  loading: Loading
});

export const WorkcapMutation = Loadable({
  loader: () => import('./Report/WorkcapMutation'),
  loading: Loading
});

export const CashBook = Loadable({
  loader: () => import('./Report/CashBook'),
  loading: Loading
});

export const ReportOneObligor = Loadable({
  loader: () => import('./Report/OneObligor'),
  loading: Loading
});

export const Journal = Loadable({
  loader: () => import('./Report/Journal'),
  loading: Loading
});

export const Revenue = Loadable({
  loader: () => import('./Report/Revenue'),
  loading: Loading
});

export const Outcome = Loadable({
  loader: () => import('./Report/Outcome'),
  loading: Loading
});

export const Summary = Loadable({
  loader: () => import('./Report/Summary'),
  loading: Loading,
});

// etc

export const accessDened = Loadable({
  loader: () => import('../../routes/accessDened'),
  loading: Loading
});

export const AllNotification = Loadable({
  loader: () => import('./Notification'),
  loading: Loading
});
