/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import clsx from 'clsx';
import {
  Toolbar,
  Typography,
  IconButton,
  Avatar,
  MenuItem,
  Menu,
  Icon,
  withStyles,
  ListItemIcon,
  ListItemText,
  Button
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import MenuIcon from '@material-ui/icons/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useStyles from './appbar-jss';
import logo from '../../assets/icon/logo.png';
import Notification from '../Notification';
import CustomBadge from '../Notification/CustomBadge';
import { setPicture } from '../../redux';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    minWidth: 340,
    borderRadius: 10
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

const onClickSignOut = () => {
  localStorage.clear();
  window.location = '/login';
};

const Header = ({ mainMenu, open, setMobileMenuOpen, mobileMenuOpen }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null); // buat dialog notif
  const [menuOpen, setMenuOpen] = useState(false);
  const photo = useSelector(({ userAccount }) => userAccount.picture);

  useEffect(() => {
    dispatch(setPicture(localStorage.getItem('photo')));
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickNotif = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleCloseNotif = () => {
    setMenuOpen(!menuOpen);
    setAnchorEl2(null);
  };

  const menuDekstop = () => (
    <StyledMenu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      className={classes.menuDekstop}
    >
      <StyledMenuItem
        onClick={() => {
          handleClose();
          history.replace('/app/profile');
        }}
        className={classes.menuItem}
      >
        <ListItemIcon>
          <Avatar>
            <Icon>person</Icon>
          </Avatar>
        </ListItemIcon>
        <ListItemText primary="Profile" secondary="Lihat dan Ubah Profil" />
      </StyledMenuItem>
      <StyledMenuItem
        onClick={() => {
          handleClose();
          history.replace('/app/change-password');
        }}
        className={classes.menuItem}
      >
        <ListItemIcon>
          <Avatar>
            <Icon>settings_icon</Icon>
          </Avatar>
        </ListItemIcon>
        <ListItemText primary="Pengaturan" secondary="Ubah Password" />
      </StyledMenuItem>

      <div className={classes.signOutButton}>
        <Button color="primary" variant="outlined" onClick={onClickSignOut}>
          Sign Out
        </Button>
      </div>
    </StyledMenu>
  );

  return (
    <AppBar
      position="fixed"
      color="inherit"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open
      })}
    >
      <Toolbar className={classes.toolBar}>
        <div className={classes.menuIcon}>
          <IconButton
            className={classes.menuIcon}
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <div
          className={clsx(classes.toolbarTitle, {
            [classes.toolbarTitleShift]: open
          })}
        >
          <Typography variant="h6" noWrap style={{ fontWeight: 'bold' }}>
            {mainMenu}
          </Typography>
        </div>
        <div className={classes.mobileTitle}>
          <img src={logo} alt="logo" height="60" />
        </div>
        <div className={classes.notificationIcon}>
          <CustomBadge handleClickNotif={handleClickNotif} classes={classes} />
        </div>
        <Typography className={classes.typography}>
          Hi, {localStorage.getItem('name') || ''}
          <Typography style={{ fontSize: 12 }}>
            {localStorage.getItem('role_name')}
          </Typography>
        </Typography>
        <IconButton onClick={handleClick} className={classes.avatarIcon}>
          <Avatar alt="avatar" src={photo} className={classes.avatar} />
        </IconButton>
        {menuDekstop()}
        <Notification anchorEl2={anchorEl2} handleClose={handleCloseNotif} />
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  mainMenu: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  mobileMenuOpen: PropTypes.bool.isRequired,
  setMobileMenuOpen: PropTypes.func.isRequired
};

export default memo(Header);
